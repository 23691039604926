<template>
  <div
    class="LibGridLayout"
    ref="LibGridLayout"
    :style="{ gridTemplateColumns: 'repeat(' + count + ', 1fr)', gridGap: gap }"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "LibGridLayout",
  props: {
    count: {
      type: Number,
      default: 3,
    },
    gap: {
      type: String,
      default: "0px",
    },
    eqhMultiple: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    let childrens = [];
    const updateHeight = () => {};
    childrens = [...this.$refs.LibGridLayout.children];
    let a = null;
    (function fn() {
      if (this.eqhMultiple > 0 && childrens?.length) {
        updateHeight();
      }
      a = requestAnimationFrame(fn);
    })();
    // 3秒后还未获取到插槽元素，则取消获取
    setTimeout(() => {
      cancelAnimationFrame(a);
    }, 3000);
  },
  methods: {
    updateHeight() {
      this.childrens.forEach((item) => {
        item.style.height = `${item.scrollWidth * this.eqhMultiple}px`;
      });
      window.addEventListener("resize", this.fn);
    },
    fn() {
      requestAnimationFrame(() => {
        this.childrens.forEach((item) => {
          item.style.height = `${item.offsetWidth * this.eqhMultiple}px`;
        });
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateHeight);
  },
};
</script>
<style scoped lang="less">
.LibGridLayout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: row dense;
  align-content: flex-start;
  overflow: auto;
  padding: 15px;
}
</style>
