<template>
  <div class="lib-stepper">
    <div class="step">
      <i class="iconfont icon-jianshao" @mousedown="sub"></i>
      <input type="text" :value="value" @input="input($event.target.value)" />
      <i class="iconfont icon-jiahao" @mousedown="add"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "LibStepper",
  props: {
    value: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      timer: 0,
      num: 1,
    };
  },
  components: {},
  created() {
    window.addEventListener("mouseup", () => {
      this.up();
    });
  },
  methods: {
    input(value) {
      if (!Number(value) || isNaN(value)) {
        this.$emit("input", 1);
        return;
      }
      this.$emit("input", Number(value));
    },
    sub() {
      if (this.value === 1) return;
      this.$emit("input", this.value - 1);
      this.timer = setInterval(() => {
        if (this.value === 1) return;
        this.$emit("input", this.value - 1);
      }, 100);
    },
    add() {
      this.$emit("input", this.value + 1);
      this.timer = setInterval(() => {
        this.$emit("input", this.value + 1);
      }, 100);
    },
    up() {
      clearInterval(this.timer);
    },
  },
};
</script>
<style scoped lang="less">
.lib-stepper {
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--gray-a);
  .step {
    display: flex;
    align-items: center;
    .iconfont {
      font-size: 30px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 0.6;
      }
    }
    input {
      width: 3em;
      background-color: transparent;
      // border: 1px solid rgba(255, 255, 255, 0.5);
      border: none;
      font-size: 22px;
      margin: 0 0.25em;
      color: var(--gray-a);
      text-align: center;
    }
  }
}
</style>
