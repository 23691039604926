import Vue from "vue";
import VueRouter from "vue-router";
import layouts from "@/layouts/index.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/404",
    meta: {
      title: "NotFound",
    },
    component: () => import(/* webpackChunkName: "NotFound" */ "@/views/NotFound/index.vue"),
  },
  {
    path: "/home",
    component: layouts,
    redirect: "",
    children: [
      {
        path: "",
        meta: {
          title: "主页",
        },
        component: () => import(/* webpackChunkName: "login" */ "@/views/Home/index.vue"),
      },
    ],
  },
  {
    path: "/goods-detail",
    component: layouts,
    redirect: "",
    children: [
      {
        path: "",
        meta: {
          title: "商品详情",
        },
        component: () => import(/* webpackChunkName: "login" */ "@/views/GoodsDetail/index.vue"),
      },
    ],
  },
  {
    path: "/personal-center",
    component: layouts,
    redirect: "",
    children: [
      {
        path: "",
        meta: {
          title: "个人中心",
        },
        component: () => import(/* webpackChunkName: "login" */ "@/views/PersonalCenter/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.afterEach((to) => {
  document.title = `${to.meta.title || "正在进入"}-Aurora-Shop`;
});

export default router;
