<template>
  <div class="lib-button click" @click="$emit('click')"><slot>按钮</slot></div>
</template>
<script>
export default {
  name: "LibButton",
};
</script>
<style scoped lang="less">
.lib-button {
  background-color: var(--theme-color-six);
  font-size: 22px;
  padding: 0.75em 1.5em;
  border-radius: 10px;
  color: var(--white);
  width: fit-content;
  @media screen and (max-width: 960px) {
    font-size: 16px;
  }
}
</style>
