<template>
  <div class="menu-list">
    <div class="menu" @mouseleave="iconHandle('')">
      <div
        class="item"
        :class="{ active: item.label === activeValue, borderRadius: round }"
        v-for="item in menu"
        :key="item.label"
        @mouseenter="iconHandle(item.label)"
        @click="setType(item.value, item.label)"
      >
        <i class="iconfont icon-item" />
        <span class="title">{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    menu: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultValue: {
      type: String,
      default: "",
    },
    round: {
      type: Boolean,
      default: false,
    },
  },
  name: "MenuList",
  data() {
    return {
      currentValue: "",
      activeValue: "",
    };
  },
  components: {},
  created() {
    this.activeValue = this.defaultValue;
  },
  methods: {
    iconHandle(name) {
      this.currentValue = name;
    },
    setType(type, name) {
      this.activeValue = name;
      this.$emit("set-type", type);
    },
  },
};
</script>
<style scoped lang="less">
.menu-list {
  position: relative;
  width: 100%;
  background-color: var(--theme-color-three);
  .menu {
    padding-top: 10px;
    .borderRadius {
      border-radius: 5px;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 5px 20px;
      transition: all 0.25s 0.05s;
      cursor: pointer;
      color: var(--gray);
      font-size: 16px;
      &:hover {
        transition: all 0s;
        .title {
          transition: all 0s;
          color: var(--theme-color-one);
          border-left: 1px solid var(--theme-color-one);
        }
        .iconfont {
          color: var(--theme-color-one);
        }
      }
      @media screen and (max-width: 960px) {
        &:hover {
          .title {
            color: var(--gray);
          }
          .iconfont {
            color: var(--gray);
          }
        }
      }

      .iconfont {
        margin-right: 0.5em;
        font-size: 20px;
      }
      .title {
        display: inline-block;
        border-left: 1px solid var(--gray);
        padding-left: 1em;
        margin-left: 10px;
        transition: all 0.25s 0.05s;
      }
    }
  }
}

.active {
  background-color: var(--theme-color-six) !important;
  > .title {
    color: var(--theme-color-one) !important;
    border-left: 1px solid var(--theme-color-one) !important;
  }
  > .iconfont {
    color: var(--theme-color-one) !important;
  }
}
</style>
