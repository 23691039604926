import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user.js";
import goods from "./modules/goods.js";
import global from "./modules/global.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { user, goods, global },
});
