<template>
  <div class="top-title">
    <div class="title">{{ title }}</div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  name: "TopTitle",
};
</script>
<style scoped lang="less">
.top-title {
  width: 100%;
  font-weight: bold;
  padding: 0 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  background-image: linear-gradient(to left, #9dba57, #278d96, #9dba57);
  background-size: 200% 100%;
  animation: linear 3s linear infinite;
  @keyframes linear {
    0% {
      background-position: 0% 100%;
    }
    100% {
      background-position: 200% 100%;
    }
  }
  .title {
    font-size: 18px;
    line-height: 50px;
    color: var(--black);
  }
}
</style>
