<template>
  <div class="layouts">
    <!-- 导航栏 -->
    <NavBar />

    <!-- 主页面 -->
    <AppMain />
  </div>
</template>
<script>
import NavBar from "./NavBar/index.vue";
import AppMain from "./AppMain/index.vue";
export default {
  components: { NavBar, AppMain },
};
</script>
<style scoped lang="less">
.layouts {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
