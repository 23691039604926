<template>
  <transition name="fade">
    <Dialog title="绑定Steam" v-if="dialog_show" @close="close">
      <!--
      <input type="text" v-model="value" />
      <LibButton @click="bindSteam">绑定</LibButton>
      -->
      <p>请复制如下命令并发送到游戏内聊天框完成绑定:</p>
      <p>!bind {{ userId }}</p>
      <p>发送后请刷新页面查看是否绑定成功(会显示Steam头像和名称)</p>
    </Dialog>
  </transition>
</template>
<script>
import { mapActions } from "vuex";
import { bindSteam } from "@/api/index.js";
export default {
  props: ["userId"],
  data() {
    return {
      dialog_show: true,
      value: "",
    };
  },
  methods: {
    ...mapActions("user", {
      StoreGetSteamInfo: "getSteamInfo",
    }),
    /* 绑定Steam */
    bindSteam() {
      if (this.value) {
        bindSteam(this.value).then((res) => {
          if (res.data?.data) {
            this.close();
            this.$message.success("绑定成功");
            this.StoreGetSteamInfo();
          }
        });
      } else {
        this.$message.error("请输入后再绑定");
      }
    },

    /* 关闭弹窗 */
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="less">
input {
  width: 300px;
  background-color: var(--theme-color-seven);
  color: var(--white);
  border: none;
  font-size: 26px;
  padding-bottom: 5px;
  text-align: center;
  border-bottom: 2px solid var(--theme-color-six);
  margin-bottom: 2em;
  @media screen and (max-width: 960px) {
    width: 250px;
    font-size: 22px;
  }
}
</style>
