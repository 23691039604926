export const $debounceDelay = (() => {
  let timer = null;
  return (callback, wait = 800) => {
    timer && clearTimeout(timer);
    timer = setTimeout(callback, wait);
  };
})();

/* 记录已过去精确时间可或倒计时 */
export function $timeCount(now, future) {
  let times = future - now > 0 ? Math.floor((future - now) / 1000) : Math.floor((now - future) / 1000);

  const time_arr = [
    {
      y: times / 60 / 60 / 24 / 30 / 12,
    },
    {
      mon: (times / 60 / 60 / 24 / 30) % 12,
    },
    {
      d: (times / 60 / 60 / 24) % 30,
    },
    {
      h: (times / 60 / 60) % 24,
    },
    {
      min: (times / 60) % 60,
    },
    {
      s: times % 60,
    },
  ];
  let timeObj = {};
  time_arr.forEach((item) => {
    const arr = Object.entries(item)[0];
    const key = arr[0];
    const value = arr[1];
    item[key] = Math.floor(value).toString().padStart(2, "0");
    Object.assign(timeObj, item);
  });
  return timeObj;
}

/* 复制 */
export function $copy(text, fn = () => {}) {
  navigator.clipboard.writeText(text);
  fn(text);
}

/* 判断是否为函数 */
export function $isFunction(type) {
  return Object.prototype.toString.call(type) === "[object Function]";
}
