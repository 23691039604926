import Vue from "vue";
import axios from "axios";
import $store from "@/store/index.js";
import { AxiosCanceler } from "../helper/axiosCancel.js";

const axiosCanceler = new AxiosCanceler();
//#####··········域名管理··········#####//
// 通过 process.env.变量 来获取全局变量
const server = axios.create({
  baseURL: process.env.VUE_APP_API,
});

//#####·········配置默认请求··········#####//
//####········GET请求········####//
export function getReq(url, params, other = {}) {
  return server({ method: "GET", url, params, ...other });
}

//####········POST请求········####//
export function postReq(url, data, other = {}) {
  return server({ method: "POST", url, data, ...other });
}

//####········POST-params请求········####//
export function postParamsReq(url, arg, other = {}) {
  return server({ method: "POST", url, ...arg, ...other });
}

//####········PUT请求········####//
export function putReq(url, data, other = {}) {
  return server({ method: "PUT", url, data, other });
}

//####········DELETE请求········####//
export function delReq(url, data, other = {}) {
  return server({ method: "DELETE", url, data, other });
}

let loading = null;
//#####·········拦截器··········#####//
//####·······请求拦截器········####//
server.interceptors.request.use((config) => {
  axiosCanceler.addPending(config);
  if (config.headers.AuthorizationType !== "basic") {
    config.headers.Authorization = "Bearer" + localStorage.getItem("AuroraShopToken");
  }
  config.headers["lang"] = localStorage.getItem("change-language") || "zh-CN";

  if (!config.headers.noTip) {
    loading = Vue.prototype.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
  }
  return config;
});

//####·······响应拦截器········####//
server.interceptors.response.use(
  (res) => {
    const { config } = res;
    axiosCanceler.removePending(config);

    loading.close();
    return res;
  },
  (err) => {
    loading.close();
    if (err.response?.data.code === 1) {
      err.response.data.msg && Vue.prototype.$message.error(err.response.data.msg);
    }
    if (err.response?.status === 424) {
      $store.commit("user/logout", true);
    }
    return err.response;
  }
);
