export default {
  namespaced: true,
  state: {
    screen_width: 0, //设备宽度
    sidebar_show: false, //显示侧边栏
    userinfo_show: false, //显示用户信息
  },
  mutations: {
    /* 设置设备宽度 */
    setScreenWidth(state, width) {
      state.screen_width = width;
    },

    /* 设置显示/隐藏侧边栏 */
    setSidebarShow(state, flag) {
      state.sidebar_show = flag;
    },

    /* 设置显示/隐藏用户信息 */
    setUserinfoShow(state, flag) {
      state.userinfo_show = flag;
    },
  },
  actions: {},
};
