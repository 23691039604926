<template>
  <LibMask @before-close="close">
    <div class="Dialog">
      <div class="title">{{ title }}</div>
      <slot></slot>
      <i class="iconfont icon-guanbi close click" @click="close" />
    </div>
  </LibMask>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    noConfirm: {
      type: Boolean,
      default: true,
    },
  },
  name: "Dialog",
  data() {
    return {};
  },
  methods: {
    close() {
      if (this.closeTip) {
        this.$confirm("确认关闭？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$emit("close");
          })
          .catch(() => {});
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>
<style scoped lang="less">
.Dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--theme-color-seven);
  padding: 75px 125px;
  border-radius: 25px;
  .title {
    font-size: 50px;
    margin-bottom: 0.75em;
    font-weight: bold;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    color: var(--white);
    transform: translate(-130%, 100%);
  }
  @media screen and (max-width: 960px) {
    padding: 50px;
    border-radius: 25px;
    .title {
      font-size: 30px;
    }
  }
}
</style>
